import * as Sentry from '@sentry/react';
import { lazy } from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { RouterErrorBoundaryFallback500Redirect } from '../components/RouterErrorBoundary/RouterErrorBoundary';

import { RedirectTo404 } from './guards/RedirectTo404';
import { RACRouterProvider } from './providers/RACRouterProvider';

const PublicCalculator = lazy(() => import('../pages/public/calculator/PublicCalculator'));
const ErrorPage = lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage'));

const sentryCreateBrowserRouter =
  process.env.NODE_ENV === 'production' ? Sentry.wrapCreateBrowserRouter(createBrowserRouter) : createBrowserRouter;

const router = sentryCreateBrowserRouter([
  {
    element: (
      <RACRouterProvider>
        <Outlet />
      </RACRouterProvider>
    ),
    ErrorBoundary: RouterErrorBoundaryFallback500Redirect,
    children: [
      {
        path: '/',
        element: <PublicCalculator />,
      },
      {
        path: '/400',
        element: <ErrorPage />,
      },
      {
        path: '/403',
        element: <ErrorPage />,
      },
      {
        path: '/404',
        element: <ErrorPage />,
      },
      {
        path: '/500',
        element: <ErrorPage />,
      },
      {
        path: '*',
        element: <RedirectTo404 />,
      },
    ],
  },
]);

export function RouterCalculator() {
  return <RouterProvider router={router} fallbackElement={null} />;
}
